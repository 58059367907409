import {selectUserPermissionButtonList} from "../../RequestPort/user/button"
export default {
    data(){
        return{
            anthButtons:[],
            authbuttonObj:{},
            authMenuId:''
        }
    },
    methods:{
        loadbth(){
            let authObj ={
                userId:localStorage.getItem('userId'),
                permissionId:this.authMenuId
            }
            selectUserPermissionButtonList(authObj).then((res)=>{
                this.anthButtons = res.data
                for (let item in res.data){
                    this.authbuttonObj[res.data[item].name] = res.data[item].value;
                };
            })
        }
    },
    mounted() {
        let arr = JSON.parse(localStorage.getItem('MenuTree'))
        let path = this.$route.path.substr(1)
        if(path.indexOf('Menu/')>-1){
            path = this.$route.path
        }
        arr.forEach((item)=>{
            if(item.children){
                item.children.forEach((itemSon)=>{
                    if(path==itemSon.url){
                        this.authMenuId = itemSon.id
                        this.loadbth()
                    }
                })
            }else {
                if(path==item.url){
                    this.authMenuId = item.id
                    this.loadbth()
                }
            }
        })
    }
}
