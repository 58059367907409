<template>
    <div>
      <el-row>
        <el-col :span="5" >
          <div id="departTree" style="padding: 10px;margin: 10px;background-color: #FFFFFF">
            <el-tree
                style="padding-top: 20px;min-height:680px;overflow: auto"
                class="filter-tree"
                :data="treedata"
                :props="defaultProps"
                node-key="id"
                :highlight-current="true"
                :default-expanded-keys="[1]"
                @node-click="click"
                ref="tree">
            </el-tree>
          </div>
        </el-col>
        <el-col :span="19">
          <div style="margin: 10px;background-color: #FFFFFF">
            <el-button v-if="showAddBtn" type="success" size="mini" icon="el-icon-plus" @click="addBtn" style="float: right;margin: 10px">添加按钮</el-button>
            <el-table
                :data="buttonList"
                style="margin-top: 20px"
                stripe
                :header-cell-style="{color:'#333',fontFamily:'MicrosoftYaHeiUI',fontSize:'14px',fontWeight:900,background:'#f8f8f9'}">
              <el-table-column type="index"  label="编号" align="center" width="80"></el-table-column>
              <el-table-column align="center" prop="name" label="按钮名称" sortable></el-table-column>
              <el-table-column align="center" prop="code" label="按钮编码" sortable></el-table-column>
              <el-table-column align="center" prop="buttonType" label="按钮类型" sortable>
                <template slot-scope="{row}">
                  <el-tag v-if="row.buttonType==0" type="warning">
                    非大屏展示
                  </el-tag>
                  <el-tag v-if="row.buttonType==1" type="success">
                    大屏展示
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="generalStatus" label="按钮状态" sortable>
                <template slot-scope="{row}">
                  <el-tag v-if="row.generalStatus==0" type="danger">
                    禁用
                  </el-tag>
                  <el-tag v-if="row.generalStatus==1" type="success">
                    启用
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="createBy" label="创建人" sortable></el-table-column>
              <el-table-column align="center" prop="createTime" label="创建时间" sortable></el-table-column>
              <el-table-column align="left"  label="操作">
                <template slot-scope="{row}">
                  <el-button type="text" @click="updataBtn(row)" size="small">编辑</el-button>
                  <el-button type="text" @click="del(row)" size="small" style="color:red">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-dialog
          :title="updataStatus==0?'添加按钮信息':'编辑按钮信息'"
          :visible.sync="dialogVisible"
          width="30%"
          :close-on-click-modal="false"
          @close="clearnF('form')">
        <el-form ref="form" :model="form" label-width="100px" :rules="rule">
            <el-form-item label="按钮名称：" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          <el-form-item label="按钮编码：" prop="code">
              <el-input v-model="form.code"></el-input>
            </el-form-item>
            <el-form-item label="按钮类型：" prop="buttonType">
                <el-radio-group v-model="form.buttonType">
                  <el-radio :label="0">非大屏展示</el-radio>
                  <el-radio :label="1">大屏展示</el-radio>
                </el-radio-group>
            </el-form-item>
          <el-form-item label="按钮状态：" prop="generalStatus">
            <el-radio-group v-model="form.generalStatus">
              <el-radio :label="0">禁用</el-radio>
              <el-radio :label="1">启用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button v-if="updataStatus==0" type="primary" @click="submitfrom('form')">确 定</el-button>
          <el-button v-else type="primary" @click="updatasubmitfrom('form')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
import { selectPermissionTreeList} from "../../RequestPort/user/menuList";
import {saveButton,selectPermissionButtonList,delbutton,uploadButton} from "../../RequestPort/user/button"
import authButtons from "../../common/js/anthButtons"
export default {
  mixins:[authButtons],
  name: "buttonManage",
  data(){
    return{
      dialogVisible:false,
      treedata:[],
      updataStatus:null,
      showAddBtn:true,
      permissionId:'',
      form:{
        name:'',
        code:'',
        buttonType:0,
        generalStatus:1
      },
      buttonList:[],
      rule:{
        name: [
          { required: true, message: '请输入按钮名称', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入按钮编码', trigger: 'blur' }
        ],
        buttonType: [
          { required: true, message: '请选择按钮类型', trigger: 'blur' }
        ],
        generalStatus: [
          { required: true, message: '请选择按钮状态', trigger: 'blur' }
        ],
      },
      defaultProps: {
        children: 'children',
        label: 'name'
      },
    }
  },
  methods:{
    clearnF(form){
      this.form = {
        name:'',
        code:'',
        buttonType:0,
        generalStatus:0
      }
      this.$refs[form].resetFields()
    },
    click(item){
      if(item.children&&item.parentId==0){
        this.showAddBtn = false
      }else {
        this.showAddBtn = true
      }
      this.permissionId = item.id
      this.loadBtnList(item.id)
    },
    submitfrom(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        this.form.permissionId = this.permissionId
        saveButton(this.form).then((res)=>{
          if(res.code===200){
            this.dialogVisible = !this.dialogVisible
            this.loadBtnList(this.permissionId)
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    updatasubmitfrom(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        uploadButton(this.form).then((res)=>{
          if(res.code===200){
            this.dialogVisible = !this.dialogVisible
            this.loadBtnList(this.form.permissionId)
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    addBtn(){
        this.updataStatus = 0
      this.dialogVisible = !this.dialogVisible
    },
    updataBtn(row){
      this.updataStatus = 1
      let obj = JSON.parse(JSON.stringify(row))
      this.form = obj
      this.form.permissionButtonId = obj.id
      this.dialogVisible  =!this.dialogVisible
    },
    del(row){
      console.log(row)
      this.$confirm(`是否删除按钮---[ ${row.name} ]?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        delbutton({permissionButtonId:row.id}).then((e)=>{
          if(e.code===200){
            this.$message.success(e.msg)
            this.loadList(this.pageParams)
          }else{
            this.$message.error(e.msg)
          }
        })
      })
    },
    loadList(){
      selectPermissionTreeList().then((res)=>{
        this.treedata = res.data
        this.permissionId  = res.data[0].children[0].id
        this.loadBtnList(this.permissionId)
      })
    },
    loadBtnList(id){
      selectPermissionButtonList({permissionId:id}).then((res)=>{
        this.buttonList = res.data
      })
    }
  },
  mounted() {
      this.loadList()

  }
}
</script>

<style>
#departTree .el-tree-node.is-current > .el-tree-node__content {
  background-color: #c2d6ea !important;

}
</style>
