import axios from '@/common/js/request'

export function saveButton(data){
    const saveButton = axios({
        url: '/permissionButton/savePermissionButton',
        method: 'post',
        data
    })
    return saveButton
}
export function selectPermissionButtonList(params){
    const selectPermissionButtonList = axios({
        url: '/permissionButton/selectPermissionButtonList',
        method: 'get',
        params
    })
    return selectPermissionButtonList
}
export function selectAllPermissionButtonList(params){
    const selectAllPermissionButtonList = axios({
        url: '/permissionButton/selectAllPermissionButtonList',
        method: 'get',
        params
    })
    return selectAllPermissionButtonList
}
export function delbutton(params){
    const delbutton = axios({
        url: '/permissionButton/deletePermissionButton',
        method: 'get',
        params
    })
    return delbutton
}
export function selectUserPermissionButtonList(params){
    const selectUserPermissionButtonList = axios({
        url: '/permissionButton/selectUserPermissionButtonList',
        method: 'get',
        params
    })
    return selectUserPermissionButtonList
}
export function uploadButton(data){
    const uploadButton = axios({
        url: '/permissionButton/updatePermissionButton',
        method: 'post',
        data
    })
    return uploadButton
}