import axios from '@/common/js/request'

export function selectPermissionTreeList(params){
    const selectPermissionTreeList = axios({
        url: '/permission/selectTreeMenuList',
        method: 'get',
        params
    })
    return selectPermissionTreeList
}
export function saveMenu(data){
    const saveMenu = axios({
        url: '/permission/saveMenu',
        method: 'post',
        data
    })
    return saveMenu
}
export function updateMenu(data){
    const updateMenu = axios({
        url: '/permission/updateMenu',
        method: 'post',
        data
    })
    return updateMenu
}
export function selectMenuMaxSort(params){
    const selectMenuMaxSort = axios({
        url: '/permission/selectPermissionMaxSortByType',
        method: 'get',
        params
    })
    return selectMenuMaxSort
}
export function delmenu(params){
    const delmenu = axios({
        url: '/permission/deleteMenu',
        method: 'get',
        params
    })
    return delmenu
}